import React, { useEffect, useState, useCallback } from 'react'
import { Table } from 'antd'
import { directDebitColumns } from './constants'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { adminServices } from '../../../services'

const DirectDebitTransactions = () => {
  const [directDebits, setDirectDebits] = useState([])
  const userIdFromParams = useParams().id
  const [loading, setLoading] = useState(true)

  const fetch = useCallback(() => {
    if (userIdFromParams) {
      adminServices
        .getUserDirectDebits(userIdFromParams)
        .then(response => {
          setDirectDebits(response?.data?.data)
        })
        .catch(error => {
          console.error(error) // Handle error if needed
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [userIdFromParams, setDirectDebits, setLoading])

  useEffect(() => {
    fetch()
  }, [userIdFromParams])

  return (
    <div className='m-5 h-screen'>
      <Table
        rowKey='transactionId'
        className='mt-5'
        columns={loading ? [] : directDebitColumns(fetch)}
        dataSource={directDebits}
        loading={loading}
        pagination={{ defaultPageSize: 10, showSizeChanger: true, hideOnSinglePage: true }}
      />
    </div>
  )
}

export default DirectDebitTransactions
